import DataTable from 'examples/Tables/DataTable';
import PropTypes from 'prop-types';
import DataFormate from './data';

function MerchantStatsPayouts(props) {
    const func = props.func
    const overview = props.merchantDetails
    const { columns, rows } = DataFormate(overview, func);
    return (
        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            notshowPagination={true}
        />
    );
}

MerchantStatsPayouts.propTypes = {
    merchantDetails: PropTypes.array,
    func: PropTypes.func
};

export default MerchantStatsPayouts;
