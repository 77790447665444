
// @mui material components
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
export default function data(logs, gatewaysHeader, merchantNames) {
    const [controller] = useMaterialUIController()
    const formatYesterdayDate = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1); // Move back 1 day

        const day = String(yesterday.getDate()).padStart(2, "0");
        const month = String(yesterday.getMonth() + 1).padStart(2, "0"); // Months are 0-based
        const year = yesterday.getFullYear();

        return `${day}/${month}/${year}`;
    };

    const yesterdayDate = formatYesterdayDate();

    const { darkMode } = controller
    return {
        columns: [
            { Header: `Yesterday ${yesterdayDate}`, accessor: "merchant", align: "left" },
            ...gatewaysHeader.map((gateway) => ({
                Header: gateway,
                accessor: gateway,
                align: "right",
            }))
        ],

        rows: merchantNames.map((merchant, index) => ({
            merchant: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium" textTransform="uppercase">
                    {merchant || ''}
                </MDTypography>
            ),
            ...gatewaysHeader.reduce((acc, gateway) => {
                acc[gateway] = (
                    <MDTypography variant="caption" color={darkMode ? "white" : "black"}
                        fontWeight={index === 0 || gateway === "Total" ? "bold" : "regular"}
                        style={{
                            fontSize: index === 0 || gateway === "Total" ? "1.2rem" : "1rem" // Adjust sizes as needed
                        }}
                    >
                        {logs[merchant]?.[gateway] ?? 0}
                    </MDTypography>
                );
                return acc;
            }, {})
        }))
    };

}
