
import DataTable from "../DataTable";
import data from "./data";
import PropTypes from 'prop-types';
const PivotDateWiseTable = (props) => {
    const { pivotDateWiseData, date } = props;
    const getGateways = (pivotDateWiseData) => {
        if (!pivotDateWiseData) return [];

        const gatewaySet = new Set();

        // Iterate through each category in pivotData
        Object.values(pivotDateWiseData).forEach((gateways) => {
            Object.keys(gateways).forEach((gateway) => {
                gatewaySet.add(gateway);
            });
        });

        return Array.from(gatewaySet);
    };
    const getMerchants = (pivotDateWiseData) => {
        if (!pivotDateWiseData) return [];

        return Object.keys(pivotDateWiseData); // Extracts top-level keys (merchant names)
    };

    const merchantNames = getMerchants(pivotDateWiseData);
    const gatewaysHeader = getGateways(pivotDateWiseData);

    const { columns, rows } = data(pivotDateWiseData, gatewaysHeader, merchantNames, date);
    return (

        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
        />

    )

}
PivotDateWiseTable.propTypes = {
    pivotDateWiseData: PropTypes.object,
    date: PropTypes.object
};
export default PivotDateWiseTable;