import { Grid } from '@mui/material'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import React, { useEffect, useState } from 'react'
import ComplexStatisticsCard from 'examples/Cards/StatisticsCards/ComplexStatisticsCard'
// import ReportInfoCard from './reportInfoCard'
import ReportCard from 'layouts/report/reportCard'
import MDTypography from 'components/MDTypography'
import { useMaterialUIController } from 'context'
import { useConfig } from "../../config"
import { useSandbox } from 'ZustandState/useSanbox'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BeatLoader from "react-spinners/BeatLoader";
import Loader from 'util/Loader'
import { capitalizeFirstLetter } from 'util/formatDateAndTime'
import { MenuItem } from '@mui/material'
import { Field, Formik, Form, ErrorMessage, } from 'formik'
import MDInput from 'components/MDInput'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import Menu from "@mui/material/Menu";
import MDButton from 'components/MDButton'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import { convertTo12HourFormat } from 'util/formatDateAndTime'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CallReceived from '@mui/icons-material/CallReceived';
import CallMade from '@mui/icons-material/CallMade';
function report() {
    const { apiUrl } = useConfig()
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const sandbox = useSandbox((state) => state.sandbox)
    const navigate = useNavigate()
    const [menu, setMenu] = useState(null);
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const [loading, setLoading] = useState(false)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const [value, setValue] = React.useState(0);
    const [controller] = useMaterialUIController()
    const {
        darkMode
    } = controller
    const [cardsData, setCardsData] = useState({
        todayVolume: 0,
        successfulTransaction: 0,
        successRate: 0,
        yesterdayVolume: 0,
        wallet: 0
    })
    const [merchantInfo, setMerchantInfo] = useState([])
    const [gateways, setGateways] = useState([])
    const [currentStatus, setCurrentStatus] = useState('success')
    const [adminBalanceUrl, setAdminBalanceUrl] = useState('/admin/getadminbalance')
    const [volumesUrl, setVolumesUrl] = useState('/admin/getalltxdetails')
    const [report, setReport] = useState()
    const [filter, setFilter] = useState({
        activeMerchant: 'all',
        activeGateway: "all",
        startDate: "all",
        endDate: "all",
        startTime: "all",
        endTime: "all",
        status: currentStatus
    })
    const closeMenu = () => setMenu(null);
    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const initialValues = {
        emailId: adminEmail,
        apiKey: adminApiKey,
        email_Id: 'all',
        gateway: 'all',
        startDate: 'all',
        endDate: 'all',
        startTime: 'all',
        endTime: 'all',
    }

    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            {/* <MenuItem onClick={() => {
                setCurrentStatus("all")
                closeMenu()
            }}>
                All
            </MenuItem> */}
            <MenuItem onClick={() => {
                setCurrentStatus("success")
                closeMenu()
            }}>Success</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("IN-PROCESS")
                closeMenu()
            }}>Pending</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("failed")
                closeMenu()
            }}>Failed</MenuItem>
        </Menu>
    );

    // with this we can get all the merchant info for dropdown 
    const getAllMerchant = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getallmerchantsinfo`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setMerchantInfo(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // with this we can get all the gateway info for dropdown 
    const getAllGateways = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getallgateways`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setGateways(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getAllGateways: ", err)
        }
    }
    // payout gateway
    const getAllPayoutGateways = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getAllPayoutGateways`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setGateways(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getAllGateways: ", err)
        }
    }
    async function getAdminBalance(body) {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            let url;
            if (value === 0) {
                url = '/admin/getadminbalance'
            }
            else {
                url = '/payouts/getPayoutsBalance'
            }
            const response = await fetch(`${apiUrl}${url}`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            return res
        } catch (err) {
            console.log("Error Fetching getAdminBalance: ", err)
        }
    }
    async function getVolumes(body) {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            let url;
            if (value === 0) {
                url = '/admin/getalltxdetails'
            }
            else {
                url = '/payouts/getPayoutsData'
            }
            const response = await fetch(`${apiUrl}${url}`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching getVolumes: ", err)
        }
    }
    const fetchData = async (body) => {
        try {
            const [walletData, volumesData] = await Promise.all([
                getAdminBalance(body),
                getVolumes(body),
            ]);
            // Once all data is fetched, update state and mark loading as false
            if (walletData && volumesData) {
                if (value === 0) {
                    setCardsData({
                        todayVolume: volumesData.todayObject.volume,
                        successfulTransaction: volumesData.last24hrSuccess,
                        successRate: volumesData.last24hrSuccessRate,
                        yesterdayVolume: volumesData.yesterdayObject.volume,
                        wallet: walletData?.responseData
                    });
                } else {
                    setCardsData({
                        todayVolume: volumesData.last24hr,
                        successfulTransaction: volumesData.last24hrSuccess,
                        successRate: volumesData.successRate === null ? 0 : volumesData.successRate,
                        yesterdayVolume: volumesData.yesterday,
                        wallet: walletData?.responseData.balance
                    });
                }
            }
        } catch (err) {
            console.log("Error Fetching Data: ", err);
        }
    };

    const downloadReport = async () => {
        setLoading(true)
        setCsvCompleteData([])
        // Create the CSV content
        const csvContent = [
            ["Merchant", "Gateway", "Start Date", "End Date", "Start Time", "End Time", "Status", "Gross Amount", "Transaction Count"],
            [
                capitalizeFirstLetter(filter.activeMerchant),
                capitalizeFirstLetter(filter?.activeGateway),
                filter?.startDate === "all" ? "Alltime" : filter?.startDate,
                filter?.endDate === "all" ? "Alltime" : filter?.endDate,
                filter?.startTime !== "all" ? convertTo12HourFormat(filter?.startTime) : "",
                filter?.endTime !== "all" ? convertTo12HourFormat(filter?.endTime) : "",
                filter?.status === "IN-PROCESS" ? "Pending" : capitalizeFirstLetter(filter?.status),
                report?.totalVolume,
                report?.totalCount
            ]].map(row => row.join(',')).join('\n');;

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Report.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
        setLoading(false)
    }
    const handleReport = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            if (values.startDate === "") {
                values.startDate = "all"
            }
            if (values.endDate === "") {
                values.endDate = "all"
            }
            if (values.startDate !== "all" && values?.endDate === "all") {
                toast.error("Please provide End Date");
                return;
            } else if (values.startDate === "all" && values.endDate !== "all") {
                toast.error("Please provide Start Date");
                return;
            } else if (values.startDate !== "all" && values.endDate !== "all") {
                const startDate = new Date(values.startDate);
                const endDate = new Date(values.endDate);

                if (endDate < startDate) {
                    toast.error("End Date should be greater than or equal to Start Date");
                    return;
                }
            } else if ((values.startTime !== "all" || values.endTime !== "all") &&
                (values.startDate === "all" || values.endDate === "all")) {
                toast.error("Please provide Start date or End date, whichever is missing");
                return;
            } else if (values.startDate === values.endDate &&
                (values.startTime !== "all" || values.endTime !== "all")) {
                const startTime = new Date(`1970-01-01T${values.startTime}`);
                const endTime = new Date(`1970-01-01T${values.endTime}`);

                // Adjust the condition to check if the endTime is earlier than or equal to startTime
                if (endTime < startTime) {
                    toast.error("End Time should be greater than Start Time");
                    return;
                }
            }

            values.status = currentStatus
            const response = await fetch(`${apiUrl}/admin/reports/getTotalVolume`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            setFilter({
                ...filter,
                activeMerchant: values.email_Id !== "all" ? merchantInfo.find((m) => m.email_id === values?.email_Id)?.business_name : "All",
                activeGateway: values?.gateway,
                startDate: values.startDate,
                endDate: values.endDate,
                startTime: values.startTime,
                endTime: values.endTime,
                status: currentStatus,

            });
            setReport(res?.responseData)
            setSubmitting(false);

        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            // Reset submitting state
            setSubmitting(false);
        }
    }
    const handleReportPayout = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            if (values.startDate === "") {
                values.startDate = "all"
            }
            if (values.endDate === "") {
                values.endDate = "all"
            }
            if (values.startDate !== "all" && values?.endDate === "all") {
                toast.error("Please provide End Date");
                return;
            } else if (values.startDate === "all" && values.endDate !== "all") {
                toast.error("Please provide Start Date");
                return;
            } else if (values.startDate !== "all" && values.endDate !== "all") {
                const startDate = new Date(values.startDate);
                const endDate = new Date(values.endDate);

                if (endDate < startDate) {
                    toast.error("End Date should be greater than or equal to Start Date");
                    return;
                }
            } else if ((values.startTime !== "all" || values.endTime !== "all") &&
                (values.startDate === "all" || values.endDate === "all")) {
                toast.error("Please provide Start date or End date, whichever is missing");
                return;
            } else if (values.startDate === values.endDate &&
                (values.startTime !== "all" || values.endTime !== "all")) {
                const startTime = new Date(`1970-01-01T${values.startTime}`);
                const endTime = new Date(`1970-01-01T${values.endTime}`);

                // Adjust the condition to check if the endTime is earlier than or equal to startTime
                if (endTime < startTime) {
                    toast.error("End Time should be greater than Start Time");
                    return;
                }
            }

            values.status = currentStatus
            const response = await fetch(`${apiUrl}/admin/reports/getTotalPayoutVolume`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            setFilter({
                ...filter,
                activeMerchant: values.email_Id !== "all" ? merchantInfo.find((m) => m.email_id === values?.email_Id)?.business_name : "All",
                activeGateway: values?.gateway,
                startDate: values.startDate,
                endDate: values.endDate,
                startTime: values.startTime,
                endTime: values.endTime,
                status: currentStatus,

            });
            setReport(res?.responseData)
            setSubmitting(false);

        } catch (error) {
            // Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            // Reset submitting state
            setSubmitting(false);
        }
    }
    const defaultReport = async (values) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                return;
            }
            values.status = currentStatus
            const response = await fetch(`${apiUrl}/admin/reports/getTotalVolume`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setReport(res.responseData)
        } catch (err) {
            console.log("Error Fetching Report: ", err)
        }
    }
    const defaultReportPayout = async (values) => {
        try {
            if (values.emailId === '' || values.apiKey === '' || value !== 1) {
                return;
            }
            values.status = currentStatus
            const response = await fetch(`${apiUrl}/admin/reports/getTotalPayoutVolume`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setReport(res.responseData)
        } catch (err) {
            console.log("Error Fetching Report: ", err)
        }
    }
    useEffect(() => {
        setAllDataLoading(true)
        getAllMerchant()
        if (value === 1) getAllPayoutGateways()
        else getAllGateways()

        let adminBody = {
            emailId: adminEmail,
            apiKey: adminApiKey
        }
        fetchData(adminBody)
        if (value === 0) {
            defaultReport(initialValues).then(() => {
                setAllDataLoading(false)
            })
        } else {
            defaultReportPayout(initialValues).then(() => {
                setAllDataLoading(false)
            })
        }
    }, [sandbox, value])
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                {
                    allDataLoading ? (<Loader />) : (
                        <MDBox mt={1}>
                            {cardsData &&
                                <Grid display={'flex'} flexDirection={'row'} container spacing={3}>
                                    <Grid ml={3} xl={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="7FDF9A"
                                                        title="Today Volume"
                                                        count={Number(cardsData?.todayVolume).toFixed(0)}
                                                    />
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        title="Successful Transactions"
                                                        color="D4BEF0"
                                                        count={cardsData.successfulTransaction}
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="BBF2EA"
                                                        title="Success Rate"
                                                        count={Number(cardsData?.successRate)?.toFixed(0) + '%'}
                                                    />
                                                </MDBox>
                                            </Grid>

                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="FFFED2"
                                                        title="Yesterday's Volume"
                                                        count={`${Number(cardsData?.yesterdayVolume).toFixed(0)}`}
                                                    />
                                                </MDBox>
                                            </Grid>
                                            <Grid item xs={12} md={2.4} lg={2.4}>
                                                <MDBox mb={1.5}>
                                                    <ComplexStatisticsCard
                                                        color="C9DEDE"
                                                        title="Wallet"
                                                        count={`${Number(cardsData.wallet).toFixed(0)}`}
                                                    />
                                                </MDBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>}
                            <MDBox
                                mt={10} mx={0.5}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="icon position tabs example"
                                >
                                    <Tab sx={{ fontWeight: "medium" }} icon={<CallReceived />} iconPosition="start" label="Payins" />
                                    <Tab sx={{ fontWeight: "medium" }} icon={<CallMade />} iconPosition="end" label="Payouts" />

                                </Tabs>
                            </MDBox>
                            <MDBox mt={2} mx={0.5} ml={3}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={value === 0 ? handleReport : handleReportPayout}
                                >
                                    {({ setFieldValue, isSubmitting }) => (
                                        <Form>
                                            <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} container spacing={3}>
                                                <Grid xl={1.3} md={2} sm={5.5} xs={5.5} >
                                                    <MDTypography variant="caption" color="text" ml={1}>
                                                        Select Merchant
                                                    </MDTypography>
                                                    <Field
                                                        type="text"
                                                        select
                                                        size="large"
                                                        as={MDInput}
                                                        fullWidth
                                                        name="email_Id"
                                                        InputProps={{
                                                            classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                        }}
                                                    >
                                                        <MenuItem value="all">All</MenuItem>
                                                        {merchantInfo && merchantInfo.map((item, index) => (
                                                            <MenuItem key={index} value={item.email_id}>
                                                                {item.business_name?.toUpperCase()}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>
                                                <Grid xl={1.3} md={2} sm={5.5} xs={5.5} >
                                                    <MDTypography variant="caption" color="text" ml={1}>
                                                        Select Gateway
                                                    </MDTypography>
                                                    <Field
                                                        type="text"
                                                        select
                                                        size="large"
                                                        as={MDInput}
                                                        fullWidth
                                                        name="gateway"
                                                        InputProps={{
                                                            classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                        }}
                                                    >
                                                        <MenuItem value="all">All</MenuItem>
                                                        {gateways && gateways.map((item, index) => (
                                                            <MenuItem key={index} value={item.gatewayName}>
                                                                {item.gatewayName?.toUpperCase()}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>
                                                <Grid xl={1.5} md={2} sm={5.5} xs={5.5} >
                                                    <MDTypography variant="caption" color="text" ml={1}>
                                                        Start date
                                                    </MDTypography>
                                                    <Field type="date" as={MDInput} fullWidth name="startDate" />
                                                </Grid>
                                                <Grid xl={1.5} md={2} sm={5.5} xs={5.5} >
                                                    <MDTypography variant="caption" color="text" ml={1}>
                                                        End date
                                                    </MDTypography>
                                                    <Field type="date" as={MDInput} fullWidth name="endDate" />
                                                </Grid>
                                                <Grid xl={1.5} md={2} sm={5.5} xs={5.5} >
                                                    <MDTypography variant="caption" color="text" ml={1}>
                                                        Start Time
                                                    </MDTypography>
                                                    <Field type="time" as={MDInput} fullWidth name="startTime" />
                                                </Grid>
                                                <Grid xl={1.5} md={2} sm={5.5} xs={5.5} >
                                                    <MDTypography variant="caption" color="text" ml={1}>
                                                        End Time
                                                    </MDTypography>
                                                    <Field type="time" as={MDInput} fullWidth name="endTime" />
                                                </Grid>
                                                <Grid display={'flex'} alignItems={'center'} mt={2}>
                                                    <MDBox
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                    >
                                                        <MDBox mb={2}>
                                                            <MDBox mt={3} ml={1} px={2}>
                                                                <TuneOutlinedIcon sx={{ cursor: "pointer", fontWeight: "bold" }} onClick={openMenu} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                            </MDBox>
                                                            {renderMenu}
                                                        </MDBox>

                                                    </MDBox>
                                                    <MDBox mt={1} pl={1} pr={4}>
                                                        {
                                                            loading ? (<BeatLoader
                                                                color="#36d7b7"
                                                                cssOverride={{}}
                                                                size={10}
                                                            />) : (
                                                                <FileDownloadOutlinedIcon onClick={() => downloadReport()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                            )
                                                        }

                                                    </MDBox>
                                                    <MDButton
                                                        type="submit"
                                                        color={'info'}
                                                        disabled={isSubmitting}
                                                    >
                                                        {isSubmitting ? 'Applying...' : 'Apply Changes'}
                                                    </MDButton>
                                                </Grid>

                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </MDBox>
                            <MDBox mt={2}>
                                <ReportCard report={report} filter={filter} />
                            </MDBox>

                        </MDBox>
                    )
                }

            </MDBox>

        </DashboardLayout>
    )
}

export default report