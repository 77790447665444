
import PropTypes from 'prop-types';
import DataFormate from './data';
import DataTable from 'examples/Tables/DataTable';

function GatewayStatsPayouts(props) {
    const func = props.func
    const overview = props.gatewayDetails
    const { columns, rows } = DataFormate(overview, func);
    
    return (
        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
            notshowPagination={true}
        />
    );
}

GatewayStatsPayouts.propTypes = {
    gatewayDetails: PropTypes.array,
    func: PropTypes.func
};

export default GatewayStatsPayouts;
