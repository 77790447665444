
import DataTable from "../DataTable";
import data from "./data";
import PropTypes from 'prop-types';
const PivotYesterdayTable = (props) => {
    const { pivotYesterdayData } = props;
    const getGateways = (pivotYesterdayData) => {
        if (!pivotYesterdayData) return [];

        const gatewaySet = new Set();

        // Iterate through each category in pivotData
        Object.values(pivotYesterdayData).forEach((gateways) => {
            Object.keys(gateways).forEach((gateway) => {
                gatewaySet.add(gateway);
            });
        });

        return Array.from(gatewaySet);
    };
    const getMerchants = (pivotYesterdayData) => {
        if (!pivotYesterdayData) return [];

        return Object.keys(pivotYesterdayData); // Extracts top-level keys (merchant names)
    };

    const merchantNames = getMerchants(pivotYesterdayData);
    const gatewaysHeader = getGateways(pivotYesterdayData);

    const { columns, rows } = data(pivotYesterdayData, gatewaysHeader, merchantNames);
    return (

        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
        />

    )

}
PivotYesterdayTable.propTypes = {
    pivotYesterdayData: PropTypes.object,
};
export default PivotYesterdayTable;