/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Transactions from "layouts/payoutDash/components/payoutTransactions"
import { useUser } from 'ZustandState/useUser';
import { useEffect, useState } from "react";
import { useConfig } from "../../config"
import Loader from "util/Loader";
import { useSandbox } from "ZustandState/useSanbox";
import MDTypography from "components/MDTypography";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import GatewayStats from "layouts/stats/gatewayStats";
import { useMaterialUIController } from "context";
import GatewayStatsPayouts from "./getewayStats";
import MerchantStatsPayouts from "./merchantStats";

function PayoutOverview() {
    const { apiUrl } = useConfig()
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const adminEmail = localStorage.getItem('admin_email')
    const adminApiKey = localStorage.getItem('admin_apiKey')
    const user = useUser((state) => state.user)
    const sandbox = useSandbox((state) => state.sandbox)
    const setUser = useUser((state) => state.setUser)
    const [allDataLoading, setAllDataLoading] = useState(true)
    const [cardsData, setCardsData] = useState({
        todayVolume: 0,
        successfulTransaction: 0,
        successRate: 0,
        yesterdayVolume: 0,
        wallet: 0
    })
    const [isLoading, setIsLoading] = useState(false);
    const [gatewayOverview, setGatewayOverview] = useState([])
    const [merchantOverview, seMerchantOverview] = useState([])
    async function setProfile(body) {
        try {

            const response = await fetch(`${apiUrl}/admin/getprofile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                return;
            } const userBody = {
                emailId: res.responseData.emailId,
                apiKey: res.responseData.apiKey
            }
            if (res.responseData) {
                setUser(userBody)
            }
            return res;
        } catch (e) {
            console.log(e)
        }
    }
    const getPayoutsOverview = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
            }
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            
            const response = await fetch(`${apiUrl}/payouts/getPayoutsOverview`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            const {responseData}=res;
            
            setGatewayOverview(responseData?.getPayoutGatewayData);
            seMerchantOverview(responseData?.merchantsData);

        } catch (err) {
            console.log("Error Fetching Gateway Overview: ", err)
        }
    }
    
    function capitalizeFirstLetter(word) {
        // Check if the input is a valid string
        if (typeof word !== 'string' || word.length === 0) {
            return word;
        }

        // Capitalize the first letter and concatenate the rest of the word
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
    useEffect(() => {
        const fetchDataAndSetLoading = async () => {
            setAllDataLoading(true)
            let adminBody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            };

            try {
                await Promise.all([
                    fetchData(adminBody),
                    getPayoutsOverview(),
                ]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setAllDataLoading(false);
            }
        };
        fetchDataAndSetLoading();
    }, [sandbox])

    async function getAdminBalance(body) {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/payouts/getPayoutsBalance`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Merchants: ", err)
        }
    }
    async function getVolumes(body) {
        try {
            if (body.emailId === '' || body.apiKey === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/payouts/getPayoutsData`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) console.log("Something went wrong")
            const res = await response.json()
            const data = res?.responseData
            return data
        } catch (err) {
            console.log("Error Fetching Merchants: ", err)
        }
    }
    const fetchData = async (body) => {
        try {
            const [walletData, volumesData] = await Promise.all([
                getAdminBalance(body),
                getVolumes(body),
            ]); 
            // Once all data is fetched, update state and mark loading as false
            if (walletData && volumesData) {
                setCardsData({
                    todayVolume: volumesData.last24hr,
                    successfulTransaction: volumesData.last24hrSuccess,
                    successRate: volumesData.successRate,
                    yesterdayVolume: volumesData.yesterday,
                    wallet: walletData.balance
                });
                setIsLoading(false);
                setAllDataLoading(false)
            }
        } catch (err) {
            console.log("Error Fetching Data: ", err);
            setIsLoading(false); // Handle errors and stop loading
        }
    };
    const downloadGatewayCsv = () => {
        // Create the CSV content
        const csvContent = [
            ["Gateway", "Today's Volume", "Successful Transactions", "Yesterday's Volume", "Yesterday Transaction",],
            ...gatewayOverview.map(({ gatewayName, payouts }) => [
                capitalizeFirstLetter(gatewayName),
                payouts?.last24hr,
                payouts?.last24hrSuccess,
                payouts?.yesterday ?? 0,
                payouts?.last24hrTotal ?? 0,
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'gatewayOverview.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
    }
    const downloadMerchantCsv = () => {
        // Create the CSV content
        const csvContent = [
            ["Merchant", "Today's Volume", "Today's Success Transactions","Today's Total Transactions" ,"Yesterday's Volume", "Yesterday Transaction Count", "Total Successful Tx","Total Transactions"],
            ...merchantOverview.map(({ business_name, payoutsData }) => [
                capitalizeFirstLetter(business_name),
                payoutsData?.last24hr,
                payoutsData?.last24hrSuccess,
                payoutsData?.last24hrTotal,
                payoutsData?.yesterday,
                payoutsData?.yesterdayTransactions,
                payoutsData?.successfulTransactions,
                payoutsData?.totalTransactions
            ]),
        ].map(row => row.join(',')).join('\n');

        // Create a Blob containing the CSV data
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'merchantOverview.csv';

        // Programmatically click the link to trigger the download
        a.click();

        // Clean up
        URL.revokeObjectURL(url);
    }
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {
                allDataLoading ? (<Loader />) : (
                    <MDBox py={2}>
                        {
                            !isLoading && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={2.5} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="7FDF9A"
                                                title="Today's Payouts"
                                                count={Number(cardsData?.todayVolume)?.toFixed(0)}
                                            />
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={2.5} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                title="Successful Payouts"
                                                color="D4BEF0"
                                                count={cardsData.successfulTransaction || 0}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={1.8} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="BBF2EA"
                                                title="Success Rate"
                                                count={cardsData?.successRate?.toFixed(0) || 0 + '%'}
                                            />
                                        </MDBox>
                                    </Grid>

                                    <Grid item xs={12} md={2.5} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="FFFED2"
                                                title="Yesterday's Payout"
                                                count={`${Number(cardsData?.yesterdayVolume)?.toFixed(0)}`}
                                            />
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={2.5} lg={2.4}>
                                        <MDBox mb={1.5}>
                                            <ComplexStatisticsCard
                                                color="C9DEDE"
                                                title="Payout Wallet"
                                                count={`${cardsData.wallet?.toFixed(0) || 0}`}
                                            />
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            )
                        }
                          <MDBox mt={1}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <MDBox display={'flex'} flexDirection={'row'} justifyContent="space-between">
                                        <MDBox>
                                            <MDTypography variant="h6" color="dark">Gateway</MDTypography>
                                        </MDBox>
                                        <MDBox alignItems="flex-end" pr={3}>
                                            <FileDownloadOutlinedIcon onClick={() => downloadGatewayCsv()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                        </MDBox>
                                    </MDBox>
                                    <GatewayStatsPayouts  gatewayDetails={gatewayOverview}  func={capitalizeFirstLetter} />
                                </Grid>
                            </Grid>
                        </MDBox>
                        <MDBox mt={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <MDBox display={'flex'} flexDirection={'row'} justifyContent="space-between">
                                        <MDBox>
                                            <MDTypography variant="h6" color="dark">Merchants</MDTypography>
                                        </MDBox>
                                        <MDBox alignItems="flex-end" pr={3}>
                                            <FileDownloadOutlinedIcon onClick={() => downloadMerchantCsv()} sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                        </MDBox>
                                    </MDBox>
                                    <MerchantStatsPayouts merchantDetails={merchantOverview}  func={capitalizeFirstLetter} />
                                </Grid>
                                
                            </Grid>
                        </MDBox>
                    </MDBox>
                )
            }
        </DashboardLayout>
    );
}

export default PayoutOverview;
