
// @mui material components
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
export default function data(logs, gatewaysHeader, merchantNames, date) {
    const [controller] = useMaterialUIController()
    const formatDateRange = (dateObj) => {
        if (!dateObj || !dateObj.start_date || !dateObj.end_date) return "";

        const format = (date) => {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, "0");
            const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
            const year = d.getFullYear();
            return `${day}/${month}/${year}`;
        };

        return `${format(dateObj.start_date)} - ${format(dateObj.end_date)}`;
    };



    const formattedDate = formatDateRange(date); // Get formatted date
    const { darkMode } = controller
    return {
        columns: [
            { Header: formattedDate, accessor: "merchant", align: "left" },
            ...gatewaysHeader.map((gateway) => ({
                Header: gateway,
                accessor: gateway,
                align: "right",
            }))
        ],

        rows: merchantNames.map((merchant, index) => ({
            merchant: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium" textTransform="uppercase">
                    {merchant || ''}
                </MDTypography>
            ),
            ...gatewaysHeader.reduce((acc, gateway) => {
                acc[gateway] = (
                    <MDTypography variant="caption" color={darkMode ? "white" : "black"}
                        fontWeight={index === 0 || gateway === "Total" ? "bold" : "regular"}
                        style={{
                            fontSize: index === 0 || gateway === "Total" ? "1.2rem" : "1rem" // Adjust sizes as needed
                        }}
                    >
                        {logs[merchant]?.[gateway] ?? 0}
                    </MDTypography>
                );
                return acc;
            }, {})
        }))
    };

}
