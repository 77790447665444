import React, { useEffect, useState } from "react";
import PayinLivePivot from "./PayinLivePivot";
import PayinYesterdayPivot from "./PayinYesterdayPivot";
import PivotDateWisePivot from "./PayinDateWisePivot";
import { useConfig } from "../../config"
import Loader from 'util/Loader'
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useMaterialUIController } from "context";
import { Grid, Tooltip } from "@mui/material";
import { Formik, Form, Field } from "formik";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { toast } from "react-toastify";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { MenuItem } from "@mui/material";
const PivotTable = () => {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [pivotLiveData, setPivotLiveData] = useState()
    const [pivotYesterdayData, setPivotYesterdayData] = useState()
    const [pivotDateRangeData, setPivotDateRangeData] = useState()
    const [isDateWise, setIsDateWise] = useState(false)
    const [csvUrl, setCsvUrl] = useState()
    const { apiUrl } = useConfig()
    const [yesterdayOnly, setYesterdayOnly] = useState(false)
    const [date, setDate] = useState(
        {
            start_date: "",
            end_date: ""
        }
    )
    const initialValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        start_date: "",
        end_date: ""
    }

    const getPivotData = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            yesterdayOnly: yesterdayOnly
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getLivePivotTable`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setIsDateWise(res?.responseData?.isDateWise);
            setCsvUrl(res?.responseData?.csvUrl);
            setPivotLiveData(res?.responseData?.structuredData?.Live);
            setPivotYesterdayData(res?.responseData?.structuredData?.Yesterday);
        } catch (err) {
            console.log("Error Fetching getPivotData: ", err)
        }
    }
    const getDateRangePivotData = async (values) => {
        try {
            if (
                (values.start_date === "" && values.end_date === "") ||
                (values.start_date !== "" && values.end_date !== "" && new Date(values.end_date) < new Date(values.start_date))
            ) {
                toast.error("Invalid Date Range", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 2 seconds
                    hideProgressBar: false,
                });
                return;
            }

            const response = await fetch(`${apiUrl}/admin/getPivotTableDatewise`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setIsDateWise(res?.responseData?.isDateWise);
            setCsvUrl(res?.responseData?.csvUrl);
            setPivotDateRangeData(res?.responseData?.structuredData);
        } catch (err) {
            console.log("Error Fetching getDateRangePivotData: ", err)
        }
    }
    const DownloadCsv = async () => {
        try {
            if (!csvUrl) return;
            window.open(csvUrl)
        } catch (err) {
            console.log("Error Fetching DownloadCsv: ", err)
        }
    }
    useEffect(() => {
        getPivotData()
    }, [yesterdayOnly])
    return (

        <DashboardLayout>
            <DashboardNavbar />
            {
                pivotLiveData || pivotYesterdayData ? (
                    <MDBox >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={4} md={4}>
                                <MDBox display="flex" alignItems="center">
                                    <MDTypography textTransform="uppercase" variant="h6" color={darkMode ? "white" : "black"}>
                                        Payin Global View
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={8} md={8} my={2} >
                                {/* Right section: Date inputs and Download button */}
                                <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={getDateRangePivotData} >
                                    {({ values, isSubmitting, setFieldValue }) => (
                                        <Form>
                                            <Grid container spacing={2} alignItems="center">
                                                {/* Start Date Input */}
                                                <Grid item xs={12} sm={6} md={2}>
                                                    <Field
                                                        as={MDInput}
                                                        label="Start Date"
                                                        size="large"
                                                        fullWidth
                                                        type="date"
                                                        name="start_date"
                                                        onChange={(e) => {
                                                            setFieldValue("start_date", e.target.value)
                                                            setDate({ ...date, start_date: e.target.value })
                                                        }}
                                                        value={values.start_date}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                                {/* End Date Input */}
                                                <Grid item xs={12} sm={6} md={2}>
                                                    <Field
                                                        as={MDInput}
                                                        label="End Date"
                                                        size="large"
                                                        fullWidth
                                                        type="date"
                                                        name="end_date"
                                                        onChange={(e) => {
                                                            setFieldValue("end_date", e.target.value)
                                                            setDate({ ...date, end_date: e.target.value })
                                                        }}
                                                        value={values.end_date}
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={2}>
                                                    <Field
                                                        type="text"
                                                        select
                                                        label="Select Global View"
                                                        size="large"
                                                        as={MDInput}
                                                        fullWidth
                                                        value={yesterdayOnly ? "yesterday" : "live"}
                                                        onChange={(e) => {
                                                            e.target.value === "live" ? setYesterdayOnly(false) : setYesterdayOnly(true)
                                                        }}
                                                        InputProps={{
                                                            classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                        }}
                                                        InputLabelProps={{ shrink: true }}
                                                    >
                                                        <MenuItem value="live">Live</MenuItem>
                                                        <MenuItem value="yesterday">Yesterday</MenuItem>
                                                    </Field>
                                                </Grid>

                                                <Grid item xs={12} sm={1} md={1} display="flex" justifyContent="center">
                                                    <Tooltip title="Reset">
                                                        <RotateLeftIcon
                                                            color={darkMode ? "white" : "dark"}
                                                            sx={{ cursor: "pointer", color: darkMode ? "white" : "black" }}
                                                            onClick={() => {
                                                                setIsDateWise(false); setFieldValue("start_date", "");
                                                                setFieldValue("end_date", "");
                                                                setYesterdayOnly(false);
                                                            }}
                                                            fontSize="medium" />
                                                    </Tooltip>
                                                </Grid>
                                                {/* submit Button */}
                                                <Grid item xs={12} sm={6} md={2} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                                                    <MDButton
                                                        type="submit"
                                                        variant="contained"
                                                        fullWidth
                                                        color={isSubmitting ? "secondary" : "success"} // Change color when submitting
                                                        sx={{
                                                            opacity: isSubmitting ? 0.5 : 1, // Fade button when submitting
                                                            cursor: isSubmitting ? "not-allowed" : "pointer", // Show disabled cursor
                                                            transition: 'opacity 0.3s ease'
                                                        }}
                                                        disabled={isSubmitting} // Disable button
                                                    >
                                                        {isSubmitting ? "Submitting..." : "Submit"} {/* Change text */}
                                                    </MDButton>
                                                </Grid>
                                                {/* Download Button */}
                                                <Grid item xs={12} sm={6} md={2.5} display="flex" justifyContent={{ xs: "center", md: "flex-start" }}>
                                                    <MDButton
                                                        variant="contained"
                                                        fullWidth
                                                        startIcon={<FileDownloadOutlinedIcon />}
                                                        onClick={DownloadCsv}
                                                        color={"info"} // Change color when submitting
                                                        sx={{
                                                            transition: 'background-color 0.3s ease',
                                                            opacity: 1, // Fade button when submitting
                                                            cursor: "pointer", // Show disabled cursor
                                                        }}
                                                    >
                                                        {"Download"} {/* Change text */}
                                                    </MDButton>
                                                </Grid>

                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                        {
                            !isDateWise ? (
                                <MDBox>
                                    {
                                        !yesterdayOnly ? (
                                            <MDBox mb={3}>
                                                <PayinLivePivot pivotLiveData={pivotLiveData} />
                                            </MDBox>
                                        ) : (
                                            <MDBox>
                                                <PayinYesterdayPivot pivotYesterdayData={pivotYesterdayData} />
                                            </MDBox>
                                        )
                                    }
                                </MDBox>

                            ) : (
                                <MDBox>

                                    <PivotDateWisePivot pivotDateWiseData={pivotDateRangeData} date={date} />
                                </MDBox>
                            )
                        }
                    </MDBox>
                ) : <Loader marginTop={25} />
            }
        </DashboardLayout>


    );
};

export default PivotTable;
