import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

export default function DataFormate( merchantDetails,func ) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return {
        columns: [
            { Header: "Merchant", accessor: "businessName", width: "10%", align: "left" },
            { Header: "Today's Volume", accessor: "todaysVolume", width: "10%", align: "center" },
            { Header: "Today's Success Tx", accessor: "last24hrSuccess", width: "10%", align: "center" },
            { Header: "Today's Total Tx", accessor: "last24hrTotal", width: "10%", align: "center" },
            { Header: "Yesterday Volume", accessor: "yesterday", width: "10%", align: "center" },
            { Header: "Yesterday Tx Count", accessor: "yesterdayTransactions", width: "10%", align: "center" },
            { Header: "Total Successful Tx", accessor: "successfulTransactions", width: "10%", align: "center" },
            { Header: "Total Transactions", accessor: "totalTransactions", width: "10%", align: "center" },
        ],

        rows: merchantDetails.map((row) => ({
            businessName: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {func(row?.business_name)}
                </MDTypography>
            ),
            todaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.payoutsData?.last24hr?.toFixed(0)}
                </MDTypography>
            ),
            last24hrSuccess: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.payoutsData?.last24hrSuccess?.toFixed(0)}
                </MDTypography>
            ),
            last24hrTotal: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.payoutsData?.last24hrTotal?.toFixed(0)}
                </MDTypography>
            ),
            yesterday: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.payoutsData?.yesterday}
                </MDTypography>
            ),
            yesterdayTransactions: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.payoutsData?.yesterdayTransactions}
                </MDTypography>
            ),
            successfulTransactions: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.payoutsData?.successfulTransactions?.toFixed(0)}
                </MDTypography>
            ),
            totalTransactions: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.payoutsData?.totalTransactions?.toFixed(0)}
                </MDTypography>
            ),
            
        }))
    };
}
