import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";

export default function DataFormate( gatewayDetails, func ) {
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    return {
        columns: [
            { Header: "Gateway", accessor: "gateway", width: "5%", align: "left" },
            { Header: "Today's Volume", accessor: "todaysVolume", width: "10%", align: "center" },
            { Header: "Successful Tx", accessor: "successfulTransaction", width: "10%", align: "center" },
            { Header: "Yesterday's Volume", accessor: "yesterdaysVolume", width: "10%", align: "center" },
            { Header: "Yesterday's Tx", accessor: "yesterdaysTransaction", width: "10%", align: "center" },
        ],

        rows: gatewayDetails.map((row) => ({
            gateway: (
                <MDTypography variant="caption" textTransform="uppercase" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {func(row?.gatewayName)}
                </MDTypography>
            ),
            todaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(row.payouts?.last24hr || 0).toFixed(0)}
                </MDTypography>
            ),
            successfulTransaction: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(row.payouts?.last24hrSuccess || 0)}
                </MDTypography>
            ),
            yesterdaysVolume: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {Number(row.payouts?.yesterday || 0).toFixed(0)}
                </MDTypography>
            ),
            yesterdaysTransaction: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {row?.payouts?.last24hrTotal || 0}
                </MDTypography>
            ),
        }))
    };
}
