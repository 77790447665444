
import DataTable from "../DataTable";
import data from "./data";
import PropTypes from 'prop-types';
const PivotLiveTable = (props) => {
    const { pivotLiveData } = props;
    const getGateways = (pivotLiveData) => {
        if (!pivotLiveData) return [];

        const gatewaySet = new Set();

        // Iterate through each category in pivotData
        Object.values(pivotLiveData).forEach((gateways) => {
            Object.keys(gateways).forEach((gateway) => {
                gatewaySet.add(gateway);
            });
        });

        return Array.from(gatewaySet);
    };
    const getMerchants = (pivotLiveData) => {
        if (!pivotLiveData) return [];

        return Object.keys(pivotLiveData); // Extracts top-level keys (merchant names)
    };

    const merchantNames = getMerchants(pivotLiveData);
    const gatewaysHeader = getGateways(pivotLiveData);

    const { columns, rows } = data(pivotLiveData, gatewaysHeader, merchantNames);
    return (

        <DataTable
            table={{ columns, rows }}
            showTotalEntries={false}
            isSorted={false}
            noEndBorder
            entriesPerPage={false}
        />

    )

}
PivotLiveTable.propTypes = {
    pivotLiveData: PropTypes.object,
};
export default PivotLiveTable;