
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import Badge from "@mui/material/Badge";
// Material Dashboard 2 React context
import { useMaterialUIController } from "context";
import { Grid } from "@mui/material";
// import { makeStyles } from "@mui/styles";
import MDInput from "components/MDInput";
import { useConfig } from "../../../config";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Switch from "@mui/material/Switch";
function Gateway({ id, gatewayName,payinLimit,abbr, noGutter, func, gatewayStatus, payoutEnabled }) {
    const { apiUrl } = useConfig()
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const [status, setStatus] = useState(gatewayStatus);
    const [payoutActive, setPayoutActive] = useState(payoutEnabled);
    const initialGatewayPayinLimit = {
        emailId: adminEmail || '',  // admin emailId from state
        apiKey: adminApiKey || '',  // admin apiKey from state 
        id:id,
        payinLimit: payinLimit || 0
    }
    const validationSchema = Yup.object({
        payinLimit: Yup.number().required('Payin Limit is required')
    });
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const handleUpdateGatewayFee = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/updateGatewayLimits`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            let adminbody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            }
            func(adminbody)
            setSubmitting(false);
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });

        } catch (error) {
            //Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });
            // Reset submitting state
            setSubmitting(false);
        }
    }

    const handleUpdateGatewayStatus = async (body) => {
        try {
            setStatus((prevIsOn) => !prevIsOn);
            if (body.emailId === '' || body.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/setgatewayswitch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                setStatus((prevIsOn) => !prevIsOn);
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            let adminbody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            }
            func(adminbody)
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });

        } catch (error) {
            //Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

        }
    }

    const handlePayoutEnabled = async (body) => {
        try {
            setPayoutActive((prevIsOn) => !prevIsOn);
            if (body.emailId === '' || body.apiKey === '') {
                toast.error("Invalid credential! Please Sign in again..", {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                localStorage.removeItem('admin_token');

                // Navigate to the sign-in page
                navigate('/authentication/sign-in');
                return;
            }
            const response = await fetch(`${apiUrl}/admin/enablePayoutGateway`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            const res = await response.json();
            if (res.responseCode !== 200) {
                setPayoutActive((prevIsOn) => !prevIsOn);
                toast.error(res.responseMessage, {
                    position: "top-right",
                    autoClose: 2000, // Auto close the toast after 3 seconds
                    hideProgressBar: false,
                });
                return;
            }
            let adminbody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || ''
            }
            func(adminbody)
            toast.success(res.responseMessage, {
                position: "top-right",
                autoClose: 2000, // Auto close the toast after 3 seconds
                hideProgressBar: false,
            });

        } catch (error) {
            //Display an error toast
            toast.error('An error occurred. Please try again later.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
            });

        }
    }


    return (
        <MDBox
            m={1}
            component="li"
            bgColor={darkMode ? "dark" : "grey-100"}
            borderRadius="lg"
            p={3}
            mb={noGutter ? 0 : 1}
            mt={2}
            width={{ xs: "100%", sm: "45%" }}
            sx={{ listStyleType: "none" }}
        >
            <Grid container mb={4} spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                        {abbr || ''}
                    </MDTypography>
                </Grid>
                <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                    <Grid container spacing={0.5} alignItems="center">
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="center"

                        >
                            <MDTypography variant="caption" fontWeight="medium" sx={{ fontSize: "0.875rem" }} noWrap>
                                GATEWAY(OFF/ON)
                            </MDTypography>
                            <Switch
                                checked={status}
                                onChange={() => {
                                    handleUpdateGatewayStatus({
                                        emailId: adminEmail || '',
                                        apiKey: adminApiKey || '',
                                        gatewayName: gatewayName,
                                        switch: status === false ? true : false,
                                    });
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            display="flex"
                            alignItems="center"
                        >
                            <MDTypography fontWeight="medium" variant="caption" sx={{ fontSize: "0.875rem" }} noWrap>
                                PAYOUT(OFF/ON)
                            </MDTypography>
                            <Switch
                                checked={payoutActive}
                                onChange={() => {
                                    handlePayoutEnabled({
                                        emailId: adminEmail || '',
                                        apiKey: adminApiKey || '',
                                        id: id,
                                        enabled: payoutActive === false ? true : false,
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Gateway form */}
            <MDBox>
                <Formik
                    initialValues={initialGatewayPayinLimit}
                    validationSchema={validationSchema}
                    onSubmit={handleUpdateGatewayFee}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MDBox mt={2}>
                                        <Field
                                            type="text"
                                            label="Payin Limit"
                                            as={MDInput}
                                            fullWidth
                                            name="payinLimit"
                                        />
                                        <MDTypography color="error" variant="caption">
                                            <ErrorMessage name="payinLimit" component="div" />
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                                {/* Button */}
                                <Grid item xs={12} display="flex" justifyContent="flex-end">
                                    <MDButton
                                        variant="gradient"
                                        color="info"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Updating...' : 'Update'}
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </MDBox>
        </MDBox>

    );
}

// Setting default values for the props of Merchant
Gateway.defaultProps = {
    noGutter: false
};

// Typechecking props for the Merchant
Gateway.propTypes = {
    id: PropTypes.string.isRequired,
    gatewayName: PropTypes.string.isRequired,
    abbr: PropTypes.string.isRequired,
    collectionFee: PropTypes.string.isRequired,
    payoutFee: PropTypes.string.isRequired,
    noGutter: PropTypes.bool,
    func: PropTypes.func,
    gatewayStatus: PropTypes.bool,
    payoutEnabled: PropTypes.bool,
    payinLimit: PropTypes.string.isRequired,
};

export default Gateway;
