import React from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import MDBox from 'components/MDBox';
import PropTypes from 'prop-types';
function Loader({ marginTop }) {
    return (
        <MDBox mt={marginTop || 25} display={'flex'} justifyContent="center">
            <ClipLoader color="#36d7b7" size={50} />
        </MDBox>
    )
}
Loader.propTypes = {
    marginTop: PropTypes.number,
};
export default Loader

